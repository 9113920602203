import React, { useState, useEffect } from "react";
import axios from "axios";
import infoIcon from "../../assets/images/svg/info-icon.svg";
import { globalConstants } from "../../services/globalVariables";
import PhoneInput from "./PhoneInput";

const BASEURL = globalConstants.BASE_URL;

const CompanyRegistrationDLC = ({ setStatus, companyId }) => {
    const [isCompanySectionVisible, setCompanySectionVisible] = useState(false);
    const [formData, setFormData] = useState({
        city: "",
        companyName: "",
        country: "US",
        dbaName: "",
        ein: "",
        email: "",
        entityType: "PRIVATE_PROFIT",
        phone: "",
        postalCode: "",
        state: "",
        street: "",
        vertical: "PROFESSIONAL",
        website: ""
    });

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [formStatus, setFormStatus] = useState("UNVERIFIED");
    const [countryCode, setCountryCode] = useState("");
    const [phone, setPhone] = useState("");
    const [loaded, setLoaded] = useState(false);

    const statusStyles = {
        UNVERIFIED: { color: "red" },
        PENDING: { color: "orange" },
        VERIFIED: { color: "green" },
        Default: { color: "red" },
    };

    const getStatusStyle = (status) => {
        return statusStyles[status] || statusStyles.Default;
    };

    useEffect(() => {
        const fetchCompanyData = async () => {
            if (companyId) {
                try {
                    const response = await axios.get(`${BASEURL}private/v1/10dlc/brand/${companyId}`);
                    if (response.status === 200) {
                        const { countryCode, phone, ...otherData } = response.data;
                        setFormData({...otherData});
                        setCountryCode(countryCode);
                        setPhone(phone);
                        setStatus(response.data.status);
                        setFormStatus(response.data.status);
                        setIsSubmitted(true);
                    } else if (response.status === 204) {
                        setStatus("UNVERIFIED");
                        setFormStatus("UNVERIFIED");
                    }
                } catch (error) {
                    console.log("Error fetching company data", error);
                    setStatus("UNVERIFIED");
                    setFormStatus("UNVERIFIED");
                }
                setLoaded(true)
            }
        };

        fetchCompanyData();
    }, [companyId]);


    useEffect(() => {
        setCompanySectionVisible(formStatus === "UNVERIFIED");
    }, [formStatus]);

    const toggleCompanySection = () => {
        setCompanySectionVisible(!isCompanySectionVisible);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (!isSubmitted) {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handlePhoneFromChild = (data) => {
        setFormData({
            ...formData,
            phone: data,
        });
    };
    const allRequiredFieldsFilled = () => {
        const { city, companyName, ein, street, state, vertical, email, postalCode, phone } = formData;
        return (
            city.trim() &&
            companyName.trim() &&
            ein.trim() &&
            street.trim() &&
            state.trim() &&
            vertical.trim() &&
            email.trim() &&
            postalCode.trim() &&
            phone.trim().length > 6
        );
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!allRequiredFieldsFilled()) {
            alert("Please fill out all required fields.");
            return;
        }
        console.log("Form submitted:", formData);

        try {
            const response = await axios.post(`${BASEURL}private/v1/10dlc/brand/${companyId}`, formData);
            console.log("Response:", response);
            if (response.status === 200 || response.status === 201) {
                window.location.reload();
                setStatus("PENDING");
                setFormStatus("PENDING");
                setIsSubmitted(true);
            }
        } catch (error) {
            console.log("Error submitting form", error);
        }
    };

    return (
        <div className="container p-4 pb-0 bg-white rounded-4">
            <div className="border-bottom pb-4">
                <div className="d-flex justify-content-between align-items-center pb-4">
                    <h2 className="fs-4 fw-normal font-weight-bold pb-0 mb-0">
                        Company <span style={getStatusStyle(formStatus)} className="fw-normal fs-6 d-inline-block">{formStatus}</span>
                    </h2>
                    <button
                        onClick={toggleCompanySection}
                        style={{
                            backgroundColor: isCompanySectionVisible ? "#0A0A0A14" : "#fff",
                            width: "64px",
                            height: "64px",
                            borderRadius: "12px",
                            padding: "4px",
                            border: "1px solid #fff",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        {isCompanySectionVisible && loaded ? (
                            <div style={{ width: "21.33px", height: "2.67px", backgroundColor: "#666666" }}></div>
                        ) : (
                            <div style={{ position: "relative", width: "21.33px", height: "2.67px", backgroundColor: "#666666" }}>
                                <div style={{ position: "absolute", width: "21.33px", height: "2.67px", backgroundColor: "#666666", transform: "rotate(90deg)" }}></div>
                            </div>
                        )}
                    </button>
                </div>
                {formStatus === "PENDING" && (
                    <div className="alert d-flex alert-warning border border-warning-subtle border-2 px-2 align-items-start" style={{ width: "555px" }} role="alert">
                        <img width="20" height="20" src={infoIcon} className="mx-2 my-1" alt="info Icon" decoding="async" />
                        <div>
                            <span>We will let you know once you are VERIFIED.</span>
                        </div>
                    </div>
                )}
            </div>

            {isCompanySectionVisible && (
                <form className="row mt-4" onSubmit={handleSubmit}>
                    <div className="col-md-6">
                        <div className="mb-5">
                            <label className="form-label fw-medium font-size-sm mb-1 text-secondary">Legal company name*</label>
                            <input
                                type="text"
                                name="companyName"
                                value={formData.companyName}
                                onChange={handleInputChange}
                                className={`form-control p-3 ${isSubmitted ? 'bg-light' : ''}`}
                                required
                                readOnly={isSubmitted}
                                tabIndex="1"
                            />
                        </div>
                        <div className="mb-5">
                            <label className="form-label fw-medium font-size-sm mb-1 text-secondary">Company Type*</label>
                            <select
                                name="entityType"
                                value={formData.entityType}
                                onChange={handleInputChange}
                                className={`form-select p-3 ${isSubmitted ? 'bg-light' : ''}`}
                                required
                                disabled={isSubmitted}
                                tabIndex="3"
                            >
                                <option value="PRIVATE_PROFIT">Private Company</option>
                            </select>
                        </div>
                        <div className="mb-5">
                            <label className="form-label fw-medium font-size-sm mb-1 text-secondary">EIN*</label>
                            <input
                                type="text"
                                name="ein"
                                value={formData.ein}
                                onChange={handleInputChange}
                                className={`form-control p-3 ${isSubmitted ? 'bg-light' : ''}`}
                                required
                                readOnly={isSubmitted}
                                tabIndex="5"
                            />
                        </div>
                        <div className="mb-5">
                            <label className="form-label fw-medium font-size-sm mb-1 text-secondary">Address/Street*</label>
                            <input
                                type="text"
                                name="street"
                                value={formData.street}
                                onChange={handleInputChange}
                                className={`form-control p-3 ${isSubmitted ? 'bg-light' : ''}`}
                                required
                                readOnly={isSubmitted}
                                tabIndex="7"
                            />
                        </div>
                        <div className="mb-5">
                            <label className="form-label fw-medium font-size-sm mb-1 text-secondary">State*</label>
                            <select
                                name="state"
                                value={formData.state}
                                onChange={handleInputChange}
                                className={`form-select p-3 ${isSubmitted ? 'bg-light' : ''}`}
                                required
                                disabled={isSubmitted}
                                tabIndex="9"
                            >
                                <option value="AL">Alabama</option>
                                <option value="AK">Alaska</option>
                                <option value="AZ">Arizona</option>
                                <option value="AR">Arkansas</option>
                                <option value="CA">California</option>
                                <option value="CO">Colorado</option>
                                <option value="CT">Connecticut</option>
                                <option value="DE">Delaware</option>
                                <option value="FL">Florida</option>
                                <option value="GA">Georgia</option>
                                <option value="HI">Hawaii</option>
                                <option value="ID">Idaho</option>
                                <option value="IL">Illinois</option>
                                <option value="IN">Indiana</option>
                                <option value="IA">Iowa</option>
                                <option value="KS">Kansas</option>
                                <option value="KY">Kentucky</option>
                                <option value="LA">Louisiana</option>
                                <option value="ME">Maine</option>
                                <option value="MD">Maryland</option>
                                <option value="MA">Massachusetts</option>
                                <option value="MI">Michigan</option>
                                <option value="MN">Minnesota</option>
                                <option value="MS">Mississippi</option>
                                <option value="MO">Missouri</option>
                                <option value="MT">Montana</option>
                                <option value="NE">Nebraska</option>
                                <option value="NV">Nevada</option>
                                <option value="NH">New Hampshire</option>
                                <option value="NJ">New Jersey</option>
                                <option value="NM">New Mexico</option>
                                <option value="NY">New York</option>
                                <option value="NC">North Carolina</option>
                                <option value="ND">North Dakota</option>
                                <option value="OH">Ohio</option>
                                <option value="OK">Oklahoma</option>
                                <option value="OR">Oregon</option>
                                <option value="PA">Pennsylvania</option>
                                <option value="RI">Rhode Island</option>
                                <option value="SC">South Carolina</option>
                                <option value="SD">South Dakota</option>
                                <option value="TN">Tennessee</option>
                                <option value="TX">Texas</option>
                                <option value="UT">Utah</option>
                                <option value="VT">Vermont</option>
                                <option value="VA">Virginia</option>
                                <option value="WA">Washington</option>
                                <option value="WV">West Virginia</option>
                                <option value="WI">Wisconsin</option>
                                <option value="WY">Wyoming</option>
                            </select>
                        </div>
                        <div className="mb-5">
                            <label className="form-label fw-medium font-size-sm mb-1 text-secondary">Industry*</label>
                            <select
                                name="vertical"
                                value={formData.vertical}
                                onChange={handleInputChange}
                                className={`form-select p-3 ${isSubmitted ? 'bg-light' : ''}`}
                                required
                                disabled={isSubmitted}
                                tabIndex="11"
                            >
                                <option value="PROFESSIONAL">Professional</option>
                                <option value="REAL_ESTATE">Real Estate</option>
                                <option value="HEALTHCARE">Healthcare</option>
                                <option value="HUMAN_RESOURCES">Human Resources</option>
                                <option value="ENERGY">Energy</option>
                                <option value="ENTERTAINMENT">Entertainment</option>
                                <option value="RETAIL">Retail</option>
                                <option value="TRANSPORTATION">Transportation</option>
                                <option value="AGRICULTURE">Agriculture</option>
                                <option value="INSURANCE">Insurance</option>
                                <option value="POSTAL">Postal</option>
                                <option value="EDUCATION">Education</option>
                                <option value="HOSPITALITY">Hospitality</option>
                                <option value="FINANCIAL">Financial</option>
                                <option value="POLITICAL">Political</option>
                                <option value="GAMBLING">Gambling</option>
                                <option value="LEGAL">Legal</option>
                                <option value="CONSTRUCTION">Construction</option>
                                <option value="NGO">Non-Profit Organization</option>
                                <option value="MANUFACTURING">Manufacturing</option>
                                <option value="GOVERNMENT">Government</option>
                                <option value="TECHNOLOGY">Technology</option>
                                <option value="COMMUNICATION">Communication</option>
                            </select>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="mb-5">
                            <label className="form-label fw-medium font-size-sm mb-1 text-secondary">Email*</label>
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                className={`form-control p-3 ${isSubmitted ? 'bg-light' : ''}`}
                                required
                                readOnly={isSubmitted}
                                tabIndex="2"
                            />
                        </div>
                        <div className="mb-5">
                            <label className="form-label fw-medium font-size-sm mb-1 text-secondary">DBA or brand
                                name</label>
                            <input
                                type="text"
                                name="dbaName"
                                value={formData.dbaName}
                                onChange={handleInputChange}
                                className={`form-control p-3 ${isSubmitted ? 'bg-light' : ''}`}
                                readOnly={isSubmitted}
                                tabIndex="4"
                            />
                        </div>
                        <div className="mb-5">
                            <label className="form-label fw-medium font-size-sm mb-1 text-secondary">Country of registration</label>
                            <select
                                name="country"
                                value={formData.country}
                                className={`form-select p-3 ${isSubmitted ? 'bg-light' : ''}`}
                                readOnly
                                disabled={isSubmitted}
                                tabIndex="6"
                            >
                                <option value="US">United States</option>
                            </select>
                        </div>
                        <div className="mb-5">
                            <label className="form-label fw-medium font-size-sm mb-1 text-secondary">City*</label>
                            <input
                                type="text"
                                name="city"
                                value={formData.city}
                                onChange={handleInputChange}
                                className={`form-control p-3 ${isSubmitted ? 'bg-light' : ''}`}
                                required
                                readOnly={isSubmitted}
                                tabIndex="8"
                            />
                        </div>
                        <div className="mb-5">
                            <label className="form-label fw-medium font-size-sm mb-1 text-secondary">Zip code*</label>
                            <input
                                type="text"
                                name="postalCode"
                                value={formData.postalCode}
                                onChange={handleInputChange}
                                className={`form-control p-3 ${isSubmitted ? 'bg-light' : ''}`}
                                required
                                readOnly={isSubmitted}
                                tabIndex="10"
                            />
                        </div>
                        <div className="mb-5">
                            <label className="form-label fw-medium font-size-sm mb-1 text-secondary">Website/Online Presence (optional)</label>
                            <input
                                type="text"
                                name="website"
                                value={formData.website}
                                onChange={handleInputChange}
                                className={`form-control p-3 ${isSubmitted ? 'bg-light' : ''}`}
                                readOnly={isSubmitted}
                                tabIndex="12"
                            />
                        </div>
                        <PhoneInput onDataChange={handlePhoneFromChild} submitHandler={isSubmitted} initialPhoneNumber={formData.phone} countryCode={countryCode} phone={phone}/>
                    </div>

                    <div className="col-12 d-flex justify-content-end mb-3">
                        <button
                            type="submit"
                            className={`btn ${isSubmitted ? "btn-secondary" : "btn-primary"}`}
                            disabled={isSubmitted || !allRequiredFieldsFilled()}
                        >
                            Submit
                        </button>
                    </div>
                </form>
            )}
        </div>
    );
};

export default CompanyRegistrationDLC;
